import React from 'react'
import { navigate } from 'gatsby'
import Fade from 'react-reveal/Fade'
import Slide from 'react-reveal/Slide'

import Layout from '../components/layout'
import SEO from '../components/seo'
import BraceletColumn from '../components/braceletColumn'
import Button from '../components/button'
import TicketForm from '../components/ticketForm'
import ContactSection from '../components/contactSection'

import ImageGroup from '../images/fecha-4.jpg'
import ImageGroup2 from '../images/fecha-4@2x.png'
import ImageGroup3 from '../images/fecha-4@3x.png'

import ImageArcar from '../images/arcar-2017-paint.png'
import ImageArcar2 from '../images/arcar-2017-paint@2x.png'
import ImageArcar3 from '../images/arcar-2017-paint@3x.png'

import ImageProjection from '../images/img-proyeccion.png'
import ImageProjection2 from '../images/img-proyeccion@2x.png'
import ImageProjection3 from '../images/img-proyeccion@3x.png'

import ImageDecoration from '../images/rectangle-9.png'
import ImageDecoration2 from '../images/rectangle-9@2x.png'
import ImageDecoration3 from '../images/rectangle-9@3x.png'

import ImageIntersection4 from '../images/intersection-4.svg'

const Tickets = () => {
  const goToProgram = () => {
    navigate('/programa')
  }
  return (
    <Layout
      gradient={{
        background: 'linear-gradient(to bottom, #202945, rgba(32, 41, 69, 0))',
      }}
    >
      <SEO title="Boletos" />
      <img
        src={ImageIntersection4}
        alt="decorator"
        className="tickets__image-decorator"
      />

      <section className="tickets__cover">
        <Fade duration={3000}>
          <img
            src={ImageGroup}
            srcSet={`${ImageGroup2} 2x,
                        ${ImageGroup3} 3x`}
            className="cover__main-image"
            alt="cover"
          />
        </Fade>
        <div className="cover__column">
          <Fade duration={3000} delay={500}>
            <img
              src={ImageArcar}
              srcSet={`${ImageArcar2} 2x,
                        ${ImageArcar3} 3x`}
              className="cover__second-image"
              alt="cover small"
            />
          </Fade>
          <Fade duration={3000} delay={400}>
            <header className="cover__header-section">
              <h1 className="cover__header">Boletos</h1>
              <h3 className="cover__subheader">adquiere los tuyos</h3>
            </header>
          </Fade>
        </div>
      </section>
      <Slide right delay={800}>
        <section className="tickets__secondary-cover">
          <img
            src={ImageProjection}
            srcSet={`${ImageProjection2} 2x,
                            ${ImageProjection3} 3x`}
            className="secondary-cover__main-image"
            alt="proyeccion"
          />
        </section>
      </Slide>
      <section className="tickets__prices">
        <BraceletColumn
          title="Pulsera"
          price='$500 USD'
          price_mx='$10,000 MXN'
          dateRange="Acceso a los eventos otoño 2020"
          paragraphs={[
            'Incluye transporte terrestre gratis del aeropuerto de Manzanillo a Careyes y regreso al aeropuerto de Manzanillo, con horarios establecidos y previa reservación.',
            'El traslado del aeropuerto de Manzanillo a Careyes en taxi, tiene un costo de $1,500 pesos en automóvil y de $3,000 en camioneta Suburban, el pago se deberá realizar directamente con el servicio de transporte.',
            'En caso requerir un vehículo privado, comunícate con Nacho Cobian directamente, de Transportes Turísticos Cobian +52 1 315 100 7018',
            'Transporte, comida y bebida en los eventos que sucedan dentro del programa del festival.',
          ]}
          id="bracelet"
        />

        {/* <BraceletColumn
                    title='Pulsera VIP'
                    price='$1200 USD'
                    dateRange='Acceso a los eventos del 26 al 29 de marzo'
                    paragraphs={['Incluye comida privada con Benicio del Toro en casa xxxxx con el chef xxxxx',
                        'Incluye transporte terrestre del aeropuerto de Manzanillo a Casa Careyes y regreso al aeropuerto de Manzanillo.',
                        'Transporte, comida y bebida en los eventos que sucedan dentro del programa del festival.'
                    ]}
                    id="bracelet-vip"
                /> */}
      </section>

      <section className="tickets__bracelet-form">
        <TicketForm isBraceletForm />
      </section>
      <section className="backgroundContact">
        <section className="tickets__additional-info" id="not-included">
          <Fade duration={2000}>
            <div className="additional-info__text-wrapper">
              <Slide left>
                <h3 className="additional-info__header">Qué no incluye</h3>
              </Slide>
              <p className="additional-info__text">
                - Traslados adicionales a las actividades del festival. En caso requerir un vehículo privado, comunícate con Nacho Cobian directamente, de Transportes Turísticos Cobian <a href="tel:+5213151007018">+52 1 315 100 7018</a>
              </p>
              {/* <p className="additional-info__text">- Traslados del aeropuerto al festival</p> */}
              <p className="additional-info__text">- Hospedaje </p>
              <p className="additional-info__text">- Boletos de avión</p>
              <p className="additional-info__text">
                - Comidas adicionales al festival
              </p>
            </div>
          </Fade>
          <div></div>
          <div className="additional-info__wrapper">
            <Slide right>
              <img
                src={ImageDecoration}
                srcSet={`${ImageDecoration2} 2x,
                            ${ImageDecoration3} 3x`}
                className="additional-info__image"
                alt="cover"
              />
            </Slide>
          </div>
        </section>
        <Fade cascade duration={2000} delay={300}>
          <ContactSection id="contact" type="hosting" />
        </Fade>

        <Fade cascade duration={2000} delay={400}>
          <section className="tickets__message">
            <h1 className="message__title">Conoce las actividades</h1>
            <h3 className="message__subtitle">dentro del programa 2020</h3>
            <Button onClick={goToProgram}>IR A PROGRAMA 2020</Button>
          </section>
        </Fade>
      </section>
    </Layout>
  )
}

export default Tickets
